import React from 'react'
// import { Link } from 'gatsby'
// import Whatsapp from '../../assets/icons/bt-solicite.svg'
import './SectionWhatsapp.css'
// import Whatsapp from '../../assets/icons/wpp.svg'

export function SectionWhatsapp ({ numero }) {
  // const regex = /[^0-9]+/g
  // const [mostrarTexto, setMostrarTexto] = useState(false)

  // const handleHover = () => {
  //   setMostrarTexto(true)
  // }

  // const handleLeave = () => {
  //   setMostrarTexto(false)
  // }
  // const botaoClasses = `d-flex flex-row-reverse ${mostrarTexto ? 'botao-whats-fixo-true' : 'botao-whats-fixo'}`
  return (
  //   <a href='https://api.whatsapp.com/send?=pt_BR&phone=5511947796762'>
  //   <button
  //   className={botaoClasses}
  //   onMouseEnter={handleHover}
  //   onMouseLeave={handleLeave}
  // >
  //     {mostrarTexto && (
  //       <p className='mt-3 botao-whats-fixo-text'>
  //         Solicite agora!
  //       </p>
  //     )}
  //     <img src={Whatsapp} alt='Whatsapp' className='botao-fixo-zap' />
  // </button>
  // </a>
  <></>
  )
}

export default SectionWhatsapp
