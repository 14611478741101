/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
import React, { Component, Fragment } from 'react'
import { graphql } from 'gatsby'
import MenuFiltro from '../components/Ofertas/MenuFiltro'
// import ModalOfertas from '../components/modal-ofertas'
import Pagina from '../components/Pagina'
export default class OfertasApp extends Component {
  render () {
    const { data } = this.props
    let ofertas = []
    const ofertasGeral = []
    const ofertasDia = []
    const off = data.Ofertas.nodes.filter((off) => off.tariff.name !== 'OFERTAS-DO-DIA')
    const offDia = data.Ofertas.nodes.filter((off) => off.tariff.name === 'OFERTAS-DO-DIA')
    const dias = [
      { id: 1, tag: 'segunda' },
      { id: 2, tag: 'terca' },
      { id: 3, tag: 'quarta' },
      { id: 4, tag: 'quinta' },
      { id: 5, tag: 'sexta' },
      { id: 6, tag: 'sabado' },
      { id: 7, tag: 'domingo' }
    ]
    const diaHoje = new Date().getDay()

    const hoje = dias.filter((dia) => dia.id === diaHoje)[0]

    offDia.map(off => {
      off.offer.map(oferta => {
        if (oferta.tag) {
          if (oferta.tag.indexOf(hoje.tag) !== -1) {
            ofertasDia.push(oferta)
          }
        }
      })
    })
    off.map(off => {
      off.offer.map(oferta => {
        ofertasGeral.push(oferta)
      })
    })
    ofertas = ofertasDia.sort(function (a, b) { return a.order - b.order }).concat(ofertasGeral.sort(function (a, b) { return a.order - b.order }))
    return (
      <Fragment>
        {/* <ModalOfertas /> */}
        <Pagina pagina={data.pagina} />
        <MenuFiltro
          ofertas={ofertas}
        />

      </Fragment>
    )
  }
}
export const query = graphql`
{
  pagina: contentfulPagina(path: { eq: "/ofertas/" }) {
    path
    metaTitle
    metaDescription
    tags
  }
  Ofertas: allCloudOfertasTabloide {
    nodes {
      legalText
      finishDate
      startDate
      status
      tariff {
        name
      }
      offer {
        timeToShow
        offerGroup {
          name
        }
        category {
          name
          id
        }
        tag
        startDate
        finishDate
        image {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
        ownBrand
        specialOffers
        order
        format
        id
      }
    }
  }
}
`
